<template>
  <div class="card">
    <h2 class="card-title">Mis recibos</h2>
    <div class="half my-1">
      <div class="cols3">
        <div class="">
          <pd-input
            placeholder="MES"
            label="MES:"
            id="mes"
            :select="true"
            v-model="month"
          >
            <template slot="options">
              <option :value="null" selected>Seleccione mes</option>
              <option v-for="m in meses" :value="m" :key="m">
                {{ m }}
              </option>
            </template>
          </pd-input>
        </div>
        <div class="">
          <pd-input
            placeholder="AÑO"
            label="AÑO:"
            id="año"
            :select="true"
            v-model="year"
          >
            <template slot="options">
              <option :value="null" selected>Seleccione año</option>
              <option v-for="n in años" :key="n" :value="n">
                {{ n }}
              </option>
            </template>
          </pd-input>
        </div>
        <div class="d-flex">
          <button
            class="btn btn-primary btn-block"
            style="align-self: end"
            @click="buscarRecibos"
          >
            Buscar
          </button>
        </div>
      </div>
    </div>

    <table-list
      :showFilter="false"
      :data="recibos"
      :header="headers"
      :body="body"
      @rowClicked="previewRecibo"
    ></table-list>
    <ShowRecibos
      v-if="reciboSelected"
      :recibo="reciboSelected"
      @close="close"
      :readonly="false"
      :isPreview="false"
    ></ShowRecibos>
  </div>
</template>
<script>
import ShowRecibos from "./ShowRecibos.vue";
import TableList from "@/components/elements/TableList";
import { RecibosServices } from "../services/RecibosServices";
import moment from "moment";
import Swal from "sweetalert2";
require("moment/locale/es");
export default {
  name: "MisRecibos",
  components: {
    ShowRecibos,
    TableList,
  },
  data() {
    return {
      recibos: [],
      reciboSelected: null,
      headers: RecibosServices.header,
      body: RecibosServices.body,
      month: "",
      year: "",
    };
  },
  computed: {
    años: function () {
      let array = [];
      for (let index = 2016; index <= 2035; index++) {
        array.push(index);
      }
      return array;
    },
    meses: function () {
      return [
        "ENERO",
        "FEBRERO",
        "MARZO",
        "ABRIL",
        "MAYO",
        "JUNIO",
        "JULIO",
        "AGOSTO",
        "SEPTIEMBRE",
        "OCTUBRE",
        "NOVIEMBRE",
        "DICIEMBRE",
      ];
    },
  },
  mounted() {
    this.year = moment(this.fecha).format("YYYY");
    this.month = moment(this.fecha).format("MMMM").toUpperCase();
    RecibosServices.api
      .misRecibos()
      .then((response) => {
        this.recibos = response.recibos;
      })
      .catch((error) => {
          Swal.fire(
              "Error",
              `<p>Ocurrió un error en el sistema.</p>
              <p><b>${error}</b></p>`,
              "error"
            );
      });
  },
  methods: {
    previewRecibo(recibo) {
      RecibosServices.api
        .pendientesPrevios(recibo)
        .then((response) => {
          if (response.success) {
            Swal.fire(
              "Atención",
              `<p>No puedes visualizar éste recibo ya que tienes recibos previos sin firmar.</p>
              <p>Por favor, firma el recibo pendiente de <b>${response.recibo.liquidacion.periodo}</b> antes de ingresar a éste.</p>`,
              "warning"
            );
          } else {
            this.reciboSelected = recibo;
          }
        })
        .catch((error) => {
          Swal.fire(
              "Error",
              `<p>Ocurrió un error en el sistema.</p>
              <p><b>${error}</b></p>`,
              "error"
            );
        });
      // this.reciboSelected = recibo;
    },
    buscarRecibos() {
      let params = {
        periodo: this.month + " " + this.year,
      };
      RecibosServices.api
        .misRecibosBuscar(params)
        .then((response) => {
          this.recibos = response.recibos;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    close() {
      this.reciboSelected = null;
      RecibosServices.api
        .misRecibos()
        .then((response) => {
          this.recibos = response.recibos;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>